import React, { useState } from 'react'
import { isValidEmail } from '../config/globalFunction'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { postWholesale } from '../config/api/wholesaleCrud'

function Wholesale() {

    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [country, setCountry] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        if(loading) {
            return
        }
        if(!checked) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please check the checkbox',
            })
        }
        setLoading(true)
        if (name === '' || country === '' || phone === '' || email === '') {
            setLoading(false)
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill in all the fields',
            })
        }

        if (!isValidEmail.test(email)) {
            setLoading(false)
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a valid email address',
            })
        }

        const data = {
            name,
            country,
            phone_number: phone,
            email
        }

        dispatch(postWholesale({ payload: data })).then((res) => {
            if ( res.payload && res.payload.status === "success") {
                Swal.fire({
                    icon: 'success',
                    title: 'Thank you for contacting us',
                    text: 'We will get back to you as soon as possible',
                })
                setCountry('')
                setName('')
                setPhone('')
                setEmail('')
                setChecked(false)
                setLoading(false)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong',
                })
                setLoading(false)
            }
        })

    }

    return (
        <div className="md:px-20 w-full mx-auto">
            <h1 className="text-2xl md:text-5xl font-bold text-center text-white font-inter py-3 md:py-8">WHOLESALE</h1>
            <p className="text-lg text-left text-white font-inter">If you have a brand or want to do dropshipping, we have wholesale prices which are of course much cheaper than regular prices, but still with the best quality. Please fill in the form below to contact us.</p>
            <div className="md:w-5/6 w-full my-3 md:my-8">
                <form className="flex flex-col">
                    <label htmlFor="name" className="text-white font-inter">Brand Name/Your name</label>
                    <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" className="px-4 py-2 border-2 rounded-md my-2" required />
                    <label htmlFor="country" className="text-white font-inter">Country</label>
                    <input type="text" name="country" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="country" className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                    <label htmlFor="phone" className="text-white font-inter">Phone Number</label>
                    <input type="text" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="phone number" className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                    <label htmlFor="email" className="text-white font-inter">Email</label>
                    <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email" className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                    <div className="flex items-center py-4">
                        <input type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} name="newsletter" className="mr-2" />
                        <label htmlFor="newsletter" className="text-white font-inter">We will reach out to you as soon as possible via whatsapp or email to discuss it.</label>
                    </div>
                    <button type="submit" onClick={handleSubmit} className="bg-oren text-white font-inter py-2 px-4 rounded-md">{ loading ? "Sending..." : "Send"}</button>
                </form>
            </div>
        </div>
    )
}

export default Wholesale