import React, { useEffect, useRef, useState } from 'react'
import SizePic from '../assets/sizechart.png'
import SizePicKid from '../assets/sizechartkid.png'
import Dripic1 from '../assets/material/dri.png'
import Dripic2 from '../assets/material/dri1.png'
import Dripic3 from '../assets/material/dri2.png'
import HollowPic1 from '../assets/material/hollow.png'
import HollowPic2 from '../assets/material/hollow1.png'
import HollowPic3 from '../assets/material/hollow2.png'
import MosasPic1 from '../assets/material/mosas.png'
import MosasPic2 from '../assets/material/mosas1.png'
import MosasPic3 from '../assets/material/mosas2.png'
import EmbosPic1 from '../assets/material/embos.png'
import EmbosPic2 from '../assets/material/embos1.png'
import EmbosPic3 from '../assets/material/embos2.png'

function Info() {

    const [selectedMaterial, setSelectedMaterial] = useState('drifit')

    const matRef = useRef(null);

    useEffect(() => {
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        // const element = document.getElementById(href);
        // if (element) {
        //     element.scrollIntoView({ behavior: 'smooth' });
        // }
        if(href === 'price-list') {
            matRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if(href === 'material') {
            window.scrollTo(0, 90000)
        }
    }, []);

    return (
        <div className="md:px-20 w-full mx-auto py-3 md:py-10">
            <div className="w-full flex flex-row justify-between items-center mb-8">
                <h1 id='size-chart' className="text-2xl md:text-5xl font-bold text-left text-white font-inter">Size Chart</h1>
                {/* <Dropdown color={'light'} className='rounded-xl focus:border-0 focus:ring-0 focus:outline-none active:outline-none active:ring-0 active:border-0 focus:ring-transparent' label={selectedSize}>
                    <Dropdown.Item onClick={() => setSelectedSize('Kids')}>Size Kids</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectedSize('Adult')}>Size Adult</Dropdown.Item>
                </Dropdown> */}
            </div>
            <div className="flex flex-col xl:flex-row gap-2 md:gap-4">
                <img src={SizePic} alt="Size Chart" className="xl:w-1/2 md:h-[730px]" style={{ objectFit: 'contain' }} />
                <img src={SizePicKid} alt="Size Chart" className="xl:w-1/2 md:h-[730px]" style={{ objectFit: 'contain' }} />
                {/* <img src={picSize} alt="Size Chart" className="w-full md:h-[730px]" style={{ objectFit: 'contain' }} /> */}
            </div>
            <h1 id='price-list' className="text-2xl md:text-5xl font-bold text-left text-white font-inter my-8">Price List</h1>
            <img src={require('../assets/pricelist.png')} alt="" className='md:w-1/2 h-auto' />
            <div className="">
                <h1 ref={matRef} id='material-detail' className="text-2xl md:text-5xl font-bold text-left text-white font-inter my-8">Material Detail</h1>
                <div className="flex flex-row gap-2">
                    <div onClick={() => setSelectedMaterial('drifit')} className={"px-2 py-1 text-white font-inter rounded-sm text-xl hover:cursor-pointer hover:scale-105 " + (selectedMaterial === 'drifit' ? 'bg-oren' : '')}>DRIFIT</div>
                    <div onClick={() => setSelectedMaterial('hollow')} className={"px-2 py-1 text-white font-inter rounded-sm text-xl hover:cursor-pointer hover:scale-105 " + (selectedMaterial === 'hollow' ? 'bg-oren' : '')}>HOLLOW</div>
                    <div onClick={() => setSelectedMaterial('mosas')} className={"px-2 py-1 text-white font-inter rounded-sm text-xl hover:cursor-pointer hover:scale-105 " + (selectedMaterial === 'mosas' ? 'bg-oren' : '')}>MOSAS</div>
                    <div onClick={() => setSelectedMaterial('embos')} className={"px-2 py-1 text-white font-inter rounded-sm text-xl hover:cursor-pointer hover:scale-105 " + (selectedMaterial === 'embos' ? 'bg-oren' : '')}>EMBOS</div>
                </div>
            </div>
            <div className={"grid-cols-1 md:grid-cols-3 gap-2 mt-4 " + (selectedMaterial === 'drifit' ? 'grid' : 'hidden')} >
                <div className="w-full">
                    <img src={Dripic1} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
                <div className="w-full">
                    <img src={Dripic2} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
                <div className="w-full">
                    <img src={Dripic3} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
            </div>
            <div className={"grid-cols-1 md:grid-cols-3 gap-2 mt-4 " + (selectedMaterial === 'hollow' ? 'grid' : 'hidden')} >
                <div className="w-full">
                    <img src={HollowPic1} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
                <div className="w-full">
                    <img src={HollowPic2} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
                <div className="w-full">
                    <img src={HollowPic3} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
            </div>
            <div className={"grid-cols-1 md:grid-cols-3 gap-2 mt-4 " + (selectedMaterial === 'mosas' ? 'grid' : 'hidden')} >
                <div className="w-full">
                    <img src={MosasPic1} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
                <div className="w-full">
                    <img src={MosasPic2} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
                <div className="w-full">
                    <img src={MosasPic3} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
            </div>
            <div className={"grid-cols-1 md:grid-cols-3 gap-2 mt-4 " + (selectedMaterial === 'embos' ? 'grid' : 'hidden')} >
                <div className="w-full">
                    <img src={EmbosPic1} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
                <div className="w-full">
                    <img src={EmbosPic2} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
                <div className="w-full">
                    <img src={EmbosPic3} alt="Size Chart" className="md:h-[330px] w-full" style={{ objectFit: 'cover' }} />
                </div>
            </div>
        </div>
    )
}

export default Info