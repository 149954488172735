import React, { useState } from 'react'
import CardProduct from '../components/CardProduct'
import { categories, products, totalProduct } from '../config/products'

function Product() {
    
    const [selectedCategory, setSelectedCategory] = useState(localStorage.getItem('cate') || 'softball')

    const handleChange = (item) => {
        setSelectedCategory(item)
        localStorage.setItem('cate', item)
    }

    return (
        <div className="md:px-20 w-full mx-auto">
            <div className="w-full flex flex-row justify-between items-center">
                <h1 className="text-2xl md:text-5xl font-bold text-left text-white font-inter">Product<span className="text-gray-500 text-sm md:text-base"> {totalProduct} products</span></h1>
            </div>
            <div className="flex flex-row gap-1 md:gap-6 mt-2 overflow-x-auto md:mt-5">
                {categories.map((item, index) => ( 
                    <div key={index} onClick={() => handleChange(item.slug)} className={"md:px-2 px-1 py-1 text-white font-inter rounded-sm text-lg md:text-xl hover:cursor-pointer hover:scale-105 " + (selectedCategory === item.slug ? 'bg-oren' : '')}>{item.name}</div>
                ))}
            </div>
            <div className="w-full py-2 md:py-8 grid grid-col md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 md:gap-4">
                {/* card */}
                {products[selectedCategory].map((item, index) => (
                    <CardProduct key={index} item={item} />
                ))}
            </div>
        </div>
    )
}

export default Product