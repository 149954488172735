import { List, Modal, TextInput } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HiSearch } from "react-icons/hi"
import { categories, products } from '../config/products'

function DesignOwn() {

    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const [openModal2, setOpenModal2] = useState(false);

    const [team, setTeam] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [attachments, setAttachments] = useState([])
    const [ref, setRef] = useState()
    const [cat, setCat] = useState('mx')
    const [attRef, setAttRef] = useState('')
    const [desc, setDesc] = useState('')
    const [checkbox, setCheckbox] = useState(true)

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!checkbox) {
            return alert('Please agree to our terms and conditions')
        }
        if (team === '' || name === '' || phone === '' || email === '' || attachments === null || attRef === '' || desc === '') {
            return alert('Please fill in all the fields')
        }
        if (!isValidEmail.test(email)) {
            return alert('Please enter a valid email address')
        }
        navigate('/design-your-own/confirm', { state: { team, name, phone, email, attachments, ref, attRef, desc } })
    }

    useEffect(() => {
        // window.scrollTo(0, 0)
    }, [])

    return (
        <div className="md:px-20 w-full mx-auto py-4 md:py-10">
            <div className="w-11/12 mx-auto">
                <h1 className="text-3xl font-bold text-center text-white font-inter mb-4">HOW TO MAKE A CUSTOM DESIGN ORDER</h1>
                <List ordered>
                    <List.Item className='text-white font-inter text-lg'>Fill out the Design Request from below to start your custom jerset order.</List.Item>
                    <List.Item className='text-white font-inter text-lg'>Attach high-resolution images, logos, or sponsors details you want in your design. You can also specify your team's colors and any additional instructions in the provided box.</List.Item>
                    <List.Item className='text-white font-inter text-lg'>Optionally, select from our Catalogue designs as a template. Customize everything from team name to colors and add sponsors as needed.</List.Item>
                    {open && <List.Item className='text-white font-inter text-lg'>After submitting your Design Request, you'll be prompted to pay a $35 art deposit.</List.Item>}
                    {open && <List.Item className='text-white font-inter text-lg'>Expect a mockup via email within 1-3 business days after submitting your request and deposit. Review the design and provide feedback for any changes or approve it for production.</List.Item>}
                    {open && <List.Item className='text-white font-inter text-lg'>Once your design is approved by our Design Division, complete the Online Order Form for sizing information using our Size Chart page.</List.Item>}
                    {open && <List.Item className='text-white font-inter text-lg'>Upon submission of your order, we'll send you an invoice with payment details. Check our Price List or Package Calculator for discounts on multiple apparel items.</List.Item>}
                    {open && <List.Item className='text-white font-inter text-lg'>You need to send Proof of Payment</List.Item>}
                    {open && <List.Item className='text-white font-inter text-lg'>Once payment is confirmed and design approved, production begins. Jerseys typically arrive in 1-2 weeks (an additional week for wholesale).</List.Item>}
                    {open && <List.Item className='text-white font-inter text-lg'>For inquiries, contact us via email at sales@jipperdesign.com, by phone at +62 851 6128 0148, or visit our FAQ page for more information.</List.Item>}
                    {!open && <List.Item className='text-white font-inter text-lg'>....</List.Item>}
                </List>
                <div onClick={() => setOpen(!open)} className="flex justify-center items-center max-w-40 mx-auto bg-oren px-4 py-1 rounded hover:cursor-pointer my-4">
                    <h2 className="text-white font-inter text-xl font-semibold">{open ? 'Read Less' : 'Read More'}</h2>
                </div>
                {/* bawah */}
                <div className="my-6">
                    <h1 className="text-2xl font-bold text-center text-white font-inter mb-4">DESIGN REQUEST</h1>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                        <div className="h-2 w-2 rounded-full bg-white mr-1"></div>
                        <p className="text-white font-inter text-lg">{"Fill out this form with custom design request"}</p>
                    </div>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                        <div className="h-2 w-2 rounded-full bg-white mr-1"></div>
                        <p className="text-white font-inter text-lg">{"Pay the Design deposit on the follow up page after clicking Submit"}</p>
                    </div>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                        <div className="h-2 w-2 rounded-full bg-white mr-1"></div>
                        <p className="text-oren font-inter text-lg">{"We will send you an email with your design and instructions on how to proceed"}</p>
                    </div>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                        <div className="h-2 w-2 rounded-full bg-white mr-1"></div>
                        <p className="text-oren font-inter text-lg">{"Note : The design deposit is non-refundable"}</p>
                    </div>
                    <form className="flex flex-col mt-10 gap-2">
                        <label htmlFor="name" className="text-white font-inter">{"Team Name (on jersey)"}</label>
                        <input type="text" value={team} onChange={(e) => setTeam(e.target.value)} name="name" placeholder="Name" className="px-4 py-2 border-2 rounded-md my-2" required />
                        <label htmlFor="country" className="text-white font-inter">Your Name</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} name="country" placeholder="name" className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                        <label htmlFor="phone" className="text-white font-inter">Phone Number</label>
                        <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} name="phone" placeholder="phone number" className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                        <label htmlFor="email" className="text-white font-inter">Email</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="email" className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                        <div className="w-full h-[2px] bg-oren my-5"></div>
                        <label htmlFor="images" className="text-white font-inter">Attachments: Any Designs, logo, sponsors, high resolution images, etc.</label>
                        <div className="flex flex-row gap-8">
                            <div className="">
                                <input onChange={(e) => {
                                    if(attachments.length > 0){
                                        if(e.target.files[0].size > 5000000) {
                                            alert('File size must be less than 5 MB')
                                            return
                                        } else {
                                            const newAttachments = [...attachments, e.target.files[0]]
                                            setAttachments(newAttachments)
                                        }
                                    } else {
                                        if(e.target.files[0].size > 3000000) {
                                            alert('File size must be less than 3 MB')
                                            return
                                        } else {
                                            const newAttachments = [e.target.files[0]]
                                            setAttachments(newAttachments)
                                        }
                                    }
                                }} type="file" name="image" accept='image/*' placeholder="browse images" className="px-4 py-2 bg-white border-2 max-w-80 rounded-md my-2" required />
                            </div>
                            <div className="grid grid-cols-2 ">
                                {
                                    attachments?.map((item, index) => {
                                        return (
                                            <div key={index} className="flex flex-row items-center hover:cursor-pointer">
                                                <div className="h-2 w-2 rounded-full bg-white mr-1"></div>
                                                <p className="text-white font-inter text-lg">{item.name}</p>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    attachments.length === 0 && <p className="text-white font-inter text-lg">No attachments</p>
                                }
                            </div>
                        </div>
                        <label htmlFor="email" className="text-white font-inter">Attachments: Design references</label>
                        <div className="flex flex-col md:flex-row gap-2 md:gap-8">
                            <input multiple onChange={(e) => setAttRef(e.target.files)} type="file" accept='image/*' name="image" placeholder="browse images" className="px-4 py-2 bg-white border-2 max-w-80 rounded-md my-2" required />
                            <div onClick={() => setOpenModal2(true)} className="px-8 py-2 md:py-0 bg-white font-inter cursor-pointer max-w-80 rounded-md my-2 text-center flex items-center justify-center">
                                {ref ? ("Reference: " + ref) : "Search ID or Name product reference"}
                            </div>
                        </div>
                        {/* <label htmlFor="email" className="text-white font-inter">Color: Choose colors for your jersey</label>
                        <div className="flex flex-row gap-8">
                            <div onClick={() => setOpenModal(true)} className="font-inter py-2 border-2 border-gray-300 rounded-md my-2 bg-white px-8 cursor-pointer">
                                #ff1299
                            </div>
                            <input type="text" name="phone" placeholder="#ff1299" className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                            <input type="text" name="phone" placeholder="#ff1299" className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                        </div> */}
                        <label htmlFor="email" className="text-white font-inter">{"Describe what you want on your design  (A clearer brief will help our team create a design according to your wishes)"}</label>
                        <textarea value={desc} onChange={(e) => setDesc(e.target.value)} type="text" name="phone" placeholder="..." className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                        <div className="flex items-center py-4">
                            <input checked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} type="checkbox" name="newsletter" className="mr-2" />
                            <label onClick={() => setCheckbox(!checkbox)} htmlFor="newsletter" className="text-white font-inter hover:cursor-pointer">Please note : once you click Submit, you will be redirect to pay the art deposit. If you do not pay the deposit, you will not receive a mockup.*</label>
                        </div>
                        <button onClick={handleSubmit} type="submit" className="bg-oren text-white font-inter py-2 px-4 rounded-md max-w-80 mx-auto">PAY ART DEPOSIT</button>
                    </form>
                </div>
            </div>
            {/* modal ref */}
            <Modal show={openModal2} size="md" popup={true} onClose={() => setOpenModal2(false)} >
                <Modal.Header>
                    <p className="font-inter">Choose Your Product Reference</p>
                </Modal.Header>
                <Modal.Body>
                    <TextInput id="email4" type="text" rightIcon={HiSearch} placeholder="80COMIC" required />
                    <div className="mt-5">
                        <div className="flex flex-row gap-4 mb-4">
                            {
                                categories.map((item, index) => (
                                    <p key={index} onClick={() => setCat(item.slug)} className={"font-inter px-2 py-1 border rounded-lg cursor-pointer " + (cat === item.slug ? 'bg-oren text-white' : 'bg-gray-200')}>{item.name}</p>
                                ))
                            }
                        </div>
                        <div className="flex flex-col border-b-2 border-oren pb-1 h-96 overflow-auto">
                            {
                                products[cat].map((item, index) => (
                                    <div key={index} onClick={() => {
                                        setRef(item.slug)
                                        setOpenModal2(false)
                                    }} className="flex flex-row items-center gap-4 mb-3 hover:bg-gray-200 cursor-pointer">
                                        <img src={item.image.front} className="w-20" alt="" />
                                        <div className="flex flex-col text-black font-inter font-medium">
                                            <p className='text-gray-500'>{item.slug}</p>
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DesignOwn