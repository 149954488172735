
function calculatePrice(quantity) {
    const priceRange = [
        45,
        65,
        85,
        105,
        123,
        140,
        159,
        176,
        194,
        212,
        285,
        302,
        318,
        334,
        351
    ]
    if (quantity < 1 || quantity > 60) {
        throw new Error("Quantity must be between 1 and 60");
    }

    const rangeSize = 4; // Each range size

    // Calculate the range index (0 for 1-4, 1 for 5-8, etc.)
    const rangeIndex = Math.floor((quantity - 1) / rangeSize);

    // Calculate the price
    const price = priceRange[rangeIndex];

    return price;
}

export default calculatePrice