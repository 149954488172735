import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function CardCustom({item}) {

    const [hover, setHover] = useState(false)

    const pict = hover ? item?.image?.back : item?.image?.front

    return (
        <div className="w-full h-full delay-[300ms] duration-[600ms] taos:translate-y-[-200px] taos:opacity-0" data-taos-offset="500">
            <Link to={`/product/${item?.slug}`} className="w-full h-full flex flex-col items-center justify-center">
                <img src={pict} alt="hero" className='w-full md:h-1/3 cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />
            </Link>
            <h3 className='text-white font-inter text-xl text-left py-4'>{item?.name}</h3>
            <Link to={`/product/${item?.slug}`} className="w-full">
                <div className="text-white w-full py-2 text-center bg-oren rounded-sm font-inter hover:cursor-pointer hover:scale-105">Costumize</div>
            </Link>
        </div>
    )
}

export default CardCustom