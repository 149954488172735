import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CATEGORY, API_PRODUCT } from "..";

export const getCategories = createAsyncThunk("category/getCategories", async () => {
    try {
        const response = await axios.get(API_CATEGORY);
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }
})

export const getProducts = createAsyncThunk("product/getProducts", async () => {
    try {
        const response = await axios.get(API_PRODUCT);
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }
})