import React, { useMemo } from 'react'
import Front from '../assets/front.png'
import { Link } from 'react-router-dom'

function Cart() {

    const [cart, setCart] = React.useState(JSON.parse(localStorage.getItem('cart')) || [])

    const handleDelete = useMemo(() => {
        return (index) => {
            cart.splice(index, 1)
            localStorage.setItem('cart', JSON.stringify(cart))
            setCart(JSON.parse(localStorage.getItem('cart')))
        }
    }, [cart])

    const handleChange = useMemo(() => {
        return (index, e) => {
            if (e.target.value <= 0) {
                cart.splice(index, 1)
            } else {
                cart[index].quantity = e.target.value
            }
            localStorage.setItem('cart', JSON.stringify(cart))
            setCart(JSON.parse(localStorage.getItem('cart')))
        }
    }, [cart])

    return (
        <div className="max-w-7xl w-full mx-auto md:py-10 min-h-screen mt-4 md:mt-0">
            <div className="md:w-11/12 mx-auto flex flex-col">
                {/* head */}
                <div className="w-full flex flex-row justify-between items-center">
                    <div className="w-3/6 h-20">
                        <h1 className="text-3xl font-bold text-left text-white font-inter">Product</h1>
                    </div>
                    <div className="w-1/6 h-20">
                        <h1 className="text-3xl font-bold text-center text-white font-inter">Price</h1>
                    </div>
                    <div className="w-1/6 h-20">
                        <h1 className="text-3xl font-bold text-center text-white font-inter">Quantity</h1>
                    </div>
                    <div className="w-1/6 h-20">
                        <h1 className="text-3xl font-bold text-center text-white font-inter">Subtotal</h1>
                    </div>
                </div>
                {/* body */}
                {cart.length === 0 ? <h1 className="text-3xl font-bold text-center text-white font-inter">Cart is empty</h1> : null}
                {cart.map((item, index) => (
                    <div key={item.id} className="w-full flex flex-row justify-between items-center border-b border-oren mb-10">
                        <div className="w-3/6 h-40">
                            <div className="flex flex-row w-full gap-4">
                                <img src={item.image || Front} alt="prod" className="w-2/6 h-40" />
                                <div className="flex flex-col text-white font-inter">
                                    <h2 className="text-2xl font-bold">{item.name}</h2>
                                    {item.isProduct ? (
                                        <>
                                            <h3>Material: {item.material === "df" ? "Dry fit" : "Mosas Dry"}</h3>
                                            <h3>Size: {item.size}</h3>
                                        </>
                                    ) : (
                                        <h3>Design your own</h3>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-1/6 h-40 mr-5">
                            <h1 className="text-2xl font-bold text-right text-white font-inter">$ {item.price}</h1>
                        </div>
                        <div className="w-1/6 h-40 flex flex-row">
                            <div className="flex flex-row justify-center">
                                <input type="number" min="1" onChange={(e) => handleChange(index, e)} disabled={item.isProduct ? false : true} value={item.quantity} name="" id="" className="w-full mx-9 h-10 text-center border-white rounded-lg bg-transparent focus:ring-0 focus:outline-none focus:border-white text-white" />
                            </div>
                            <div className="flex flex-row justify-end">
                                <button onClick={() => handleDelete(index)} className="w-10 h-10 bg-red-500 rounded-full flex justify-center items-center text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="w-1/6 h-40">
                            <h1 className="text-2xl font-bold text-right text-white font-inter">$ {item.price * item.quantity}</h1>
                        </div>
                    </div>
                ))}
                {/* foot */}
                <div className="w-full flex flex-row justify-between items-center">
                    <Link to="/product" className="w-60 h-14 border-white border-2 rounded-lg flex justify-center items-center">
                        <h1 className="text-xl text-white font-inter">Return to Shop</h1>
                    </Link>
                    <Link to={cart.length > 0 ? "/checkout" : "/cart"} className="w-60 h-14 bg-oren rounded-lg flex justify-center items-center">
                        <h1 className="text-xl text-white font-inter">Checkout</h1>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Cart