import React from 'react'
import Logo from "../assets/logo.png"
import IG from "../assets/socmed-icons-01.png"
import FB from "../assets/socmed-icons-02.png"
import FI from "../assets/socmed-icons-03.png"
import MAIL from "../assets/socmed-icons-04.png"
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className="flex mx-auto w-full md:h-[320px]">
            <div className="flex w-full flex-row justify-between md:px-16 md:pt-4">
                <div className="w-2/6">
                    <div className="">
                        <img src={Logo} alt="logo" className='h-12 md:h-32 w-12 md:w-32' />
                    </div>
                    <div className="max-w-60 py-2 flex flex-row gap-1 md:gap-3">
                        <div className="border border-white rounded-full w-6 md:w-10 h-6 md:h-10 flex justify-center items-center font-bold">
                            <a href="https://www.instagram.com/jipper.design" target='_blank'>
                                <img src={IG} alt="gb" />
                            </a>
                        </div>
                        <div className="border border-white rounded-full w-6 md:w-10 h-6 md:h-10 flex justify-center items-center font-bold">
                            <a href="https://www.facebook.com/jipperdesign" target='_blank'>
                                <img src={FB} alt="gb" />
                            </a>
                        </div>
                        <div className="border border-white rounded-full w-6 md:w-10 h-6 md:h-10 flex justify-center items-center font-bold">
                            <a href="https://www.fiverr.com/jipper_design" target='_blank'>
                                <img src={FI} alt="gb" />
                            </a>
                        </div>
                        <div className="border border-white rounded-full w-6 md:w-10 h-6 md:h-10 flex justify-center items-center font-bold">
                            <a href="mailto:info@jipperdesign.com" target='_blank'>
                                <img src={MAIL} alt="gb" />
                            </a>
                        </div>
                    </div>
                    <div className="max-w-60">
                        <h3 className='text-white text-xl md:text-3xl font-inter font-bold'>JIPPER DESIGN 2020</h3>
                    </div>
                </div>
                <div className="w-3/6 pt-5 pb-4 md:pb-0">
                    <div className="flex flex-row gap-2 md:gap-10">
                        <div className="">
                            <h2 className='text-oren text-xl md:text-2xl font-inter font-bold'>PARTNER WITH US</h2>
                            <p className='text-white/60 pt-2 md:pt-4 font-inter'>Sponsorship</p>
                            <Link to="/wholesale" className='text-white/60 pt-1 font-inter'>Whole sale</Link>
                        </div>
                        <div className="">
                            <h2 className='text-oren text-xl md:text-2xl font-inter font-bold'>CUSTOMER CARE</h2>
                            <p className='text-white/60 pt-2 md:pt-4 font-inter'>FAQ</p>
                            <p className='text-white/60 pt-1 font-inter'>Term & Condition</p>
                            <Link to="/contact" className='text-white/60 pt-1 font-inter'>Contact Us</Link>
                            <p className='text-white/60 pt-1 font-inter'>Articles</p>
                        </div>
                    </div>
                </div>
                <div className="w-1/6"></div>
            </div>
        </div>
    )
}

export default Footer