import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { API_MSG } from "..";

export const postMessage = createAsyncThunk("message/postMessage", async ({ payload }) => {
    try {
        const response = await axios.post(API_MSG, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
        Swal.fire({
            title: "Attention",
            text: error.message,
            icon: "warning",
            confirmButtonText: "Yes",
        });
        return Promise.reject(error);
    }
})